// NPM
import { createRouter, createWebHistory } from 'vue-router';

// Views
import ErrorView from '@/views/ErrorView.vue';
// Login
import LoginView from '@/views/LoginView.vue';
import ExpiredLinkView from '@/views/ExpiredLinkView.vue';
import TokenView from '@/views/TokenView.vue';
// Helper
//// Campaigns Sign-up
import HelperCampaignSignUpView from '@/views/HelperCampaignSignUpView.vue';
import HelperStatusView from '@/views/HelperStatusView.vue';
//// Relations
import RelationsLayout from '@/views/relations/RelationsLayout.vue';
import RelationsEmptyState from '@/views/relations/RelationsEmptyState.vue';
import HelperRelationOverview from '@/views/relations/HelperRelationOverview.vue';
import ReportRelationView from '@/views/relations/ReportRelationView.vue'; // Common for both helpees and helpers
import HelperReferralView from '@/views/HelperReferralView.vue';
import HelperSharingView from '@/views/HelperSharingView.vue';
//// Challenges
import ChallengesLayout from '@/views/challenges/ChallengesLayout.vue';
import ChallengesView from '@/views/challenges/ChallengesView.vue';
import CompletedChallengesView from '@/views/challenges/CompletedChallengesView.vue';
//// Rewards
import RewardsLayout from '@/views/rewards/RewardsLayout.vue';
import RewardsView from '@/views/rewards/RewardsView.vue';
import PurchasedRewardsView from '@/views/rewards/PurchasedRewardsView.vue';
//// Settings
import SettingsLayout from '@/views/settings/SettingsLayout.vue';
import SettingsProfileView from '@/views/settings/SettingsProfileView.vue';
import SettingsTouchpointsView from '@/views/settings/SettingsTouchpointsView.vue';
import SettingsMatchingDetailsView from '@/views/settings/SettingsMatchingDetailsView.vue';
// Helpee
//// Campaigns Sign-up
import HelpeeMultiCampaignSignUpView from '@/views/HelpeeMultiCampaignSignUpView.vue';
import HelpeeCampaignSignUpView from '@/views/HelpeeCampaignSignUpView.vue';
import HelpeeSharingView from '@/views/HelpeeSharingView.vue';
import HelpeeReferralView from '@/views/HelpeeReferralView.vue';
//// Relations
import HelperStartRelationView from '@/views/relations/HelperStartRelationView.vue';
import HelpeeRelationOverview from '@/views/relations/HelpeeRelationOverview.vue';
import HelpeeReportFeedbackView from '@/views/HelpeeReportFeedbackView.vue';
import HelpeeStartRelationView from '@/views/relations/HelpeeStartRelationView.vue';
import HelpeeThanksView from '@/views/HelpeeThanksView.vue';
import HelpeeValidationView from '@/views/HelpeeValidationView.vue';
import HelperOnboardingView from '@/views/HelperOnboardingView.vue';
import Reviews from '@/views/reviews/Reviews.vue';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),

	routes: [
		// Error Page
		{
			path: '/:pathMatch(.*)*',
			name: 'error',
			component: ErrorView,
			props: true,
		},
		// Login
		{
			path: '/login',
			name: 'login',
			component: LoginView,
		},
		{
			// old route, redirect to new route
			path: '/sign-in/',
			redirect: () => {
				return {
					path: '/login',
				};
			},
		},
		{
			path: '/expired-link/',
			name: 'expired-link',
			component: ExpiredLinkView,
		},
		{
			path: '/t/:token',
			name: 'token',
			component: TokenView,
		},
		// Helper
		//// Campaigns Sign-up
		{
			path: '/helper-signup/:campaignUuid?',
			name: 'helper-signup',
			component: HelperOnboardingView,
		},
		{
			path: '/helper-onboarding/:campaignUuid',
			name: 'helper-onboarding',
			component: HelperOnboardingView,
		},
		{
			// old route, redirect to new route
			path: '/campaign-sign-up/:campaignUuid?',
			redirect: (to) => {
				return {
					path: '/helper-signup/' + to.params.campaignUuid,
				};
			},
		},
		{
			path: '/helper-status',
			name: 'helper-status',
			component: HelperStatusView,
		},
		{
			path: '/start-relation/helper/:relationId',
			name: 'start-relation-helper',
			component: HelperStartRelationView,
		},
		{
			path: '/start-relation/helpee/:relationId',
			name: 'start-relation-helpee',
			component: HelpeeStartRelationView,
		},
		//// Relations
		{
			path: '/',
			name: 'relations',
			component: RelationsLayout,
			children: [
				{
					path: '',
					name: 'relations-empty-state',
					component: RelationsEmptyState,
				},
				{
					path: '/helper-relation/:relationId?',
					name: 'helper-relation-overview',
					component: HelperRelationOverview,
				},
			],
		},
		{
			path: '/report/:userType/:reason/:relationId',
			name: 'report-relation-view',
			component: ReportRelationView, // Common for both helpees and helpers
		},
		{
			path: '/referral',
			name: 'referral',
			component: HelperReferralView,
		},
		{
			path: '/sharing',
			name: 'sharing',
			component: HelperSharingView,
		},
		//// Challenges
		{
			path: '/challenges',
			component: ChallengesLayout,
			children: [
				{
					path: '',
					name: 'challenges',
					component: ChallengesView,
				},
				{
					path: 'completed',
					name: 'completed-challenges',
					component: CompletedChallengesView,
				},
			],
		},
		//// Rewards
		{
			path: '/rewards',
			component: RewardsLayout,
			children: [
				{
					path: '',
					name: 'rewards',
					component: RewardsView,
				},
				{
					path: 'purchased',
					name: 'purchased-rewards',
					component: PurchasedRewardsView,
				},
			],
		},
		//// Settings
		{
			path: '/settings/settings-profile',
			component: SettingsLayout,
			children: [
				{
					path: '',
					name: 'settings-profile',
					component: SettingsProfileView,
				},
				{
					path: '/settings/touchpoints',
					name: 'settings-touchpoints',
					component: SettingsTouchpointsView,
				},
				{
					path: '/settings/matching-details',
					name: 'settings-matching-details',
					component: SettingsMatchingDetailsView,
				},
			],
		},
		// Helpee
		//// Campaigns Sign-up
		{
			path: '/helpee-campaigns/:organizationUuid?',
			name: 'helpee-campaigns',
			component: HelpeeMultiCampaignSignUpView,
			meta: { role: 'helpee' },
		},
		{
			path: '/helpee-signup/:campaignUuid?',
			name: 'helpee-signup',
			component: HelpeeCampaignSignUpView,
			meta: { role: 'helpee' },
		},
		{
			path: '/helpee-qualification/:campaignHelpeeUuid',
			name: 'helpee-qualification',
			component: HelpeeValidationView,
			meta: { role: 'helpee' },
		},
		{
			// old route, redirect to new route
			path: '/campaign-sign-up-form/:campaignUuid?',
			redirect: (to) => {
				return {
					path: '/helpee-signup/' + to.params.campaignUuid,
				};
			},
		},
		{
			path: '/sharing/:shareId',
			name: 'referred-sharing',
			component: HelpeeSharingView,
			meta: { role: 'helpee' },
		},
		{
			path: '/referred/:referredUuid',
			name: 'referred',
			component: HelpeeReferralView,
			meta: { role: 'helpee' },
		},
		//// Relations
		{
			path: '/helpee-relation/:relationId?',
			name: 'helpee-relation-overview',
			component: HelpeeRelationOverview,
			meta: { role: 'helpee' },
		},
		{
			path: '/report-feedback/:type/:relationId',
			name: 'helpee-report-feedback-view',
			component: HelpeeReportFeedbackView,
			meta: { role: 'helpee' },
		},
		{
			path: '/helpee-thanks/:campaignUuid',
			name: 'helpee-thanks',
			component: HelpeeThanksView,
		},
		/// Reviews
		{
			path: '/reviews/:uuid',
			name: 'reviews',
			component: Reviews,
		},
	],
});

export default router;
